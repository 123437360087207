
import abi from "./contract/GlobalPool.json";

import Buy from "./component/buy";
import Buy1 from "./component/buy1";
import Buy2 from "./component/buy2";
import Buy3 from "./component/buy3";
import Buy4 from "./component/buy4";
import Buy5 from "./component/buy5";
import jQuery from "jquery";
import "./css/style.css";
import "./css/bootstrap.min.css";

import "./logo.png";
import "./Global.gif";
import "./2.png";
import "./3.png";
import "./4.png";
import "./5.png";
import "./6.png";
import "./7.png";
import "./8.png";
import "./9.png";
import "./10.png";
import "./copy.png";


import { useState, useEffect } from "react";
import { ethers } from "ethers";

function App() {

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[auto,setAuto]=useState("None");
  const[auto1,setAuto1]=useState("None");
  const[auto2,setAuto2]=useState("None");
  const[auto3,setAuto3]=useState("None");
  const[auto4,setAuto4]=useState("None");
  const[auto5,setAuto5]=useState("None");
  const[auto6,setAuto6]=useState("None");
  const[auto7,setAuto7]=useState("None");
  const[bonus,setBonus]=useState("None");
  const[bonus1,setBonus1]=useState("None");
  const[pack,setpackage]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [total,setTotal]=useState("None");
const [req,setreq]=useState("None");
const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[bonanza1,setBonanza1]=useState("None");

const[wid,setwid]=useState("None");


  useEffect(() => {
    const connectWallet = async () => {
      const contractAddress = "0xffe83d20092bb0d42eac9f392a291ed11461b8aa";
      const contractABI = abi.abi;
      const searchParams = new URLSearchParams(window.location.search);
      const myParam = searchParams.get('ref');
      console.log(myParam);
      
      
      document.querySelector("#name").value=myParam;
  if(myParam == null)
  {
    document.querySelector("#name").value="0x801e255eE53497e89FB5eF832Db1F52E8C91E363";
  }
      try {
        const { ethereum } = window;
        document.querySelector("#test").value = "Upgrade USDT 30";
        if (ethereum) {
          const account = await ethereum.request({
            method: "eth_requestAccounts",
           
          });
         
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          window.ethereum.on("accountsChanged", () => {
            
            window.location.reload();
          });

          const provider = new ethers.providers.Web3Provider(ethereum);
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
          let alltasks = await contract.getMemos(account[0]);
          setTasks(alltasks);
          const b =Number(alltasks[2]);
          const refer = (alltasks[1]);
         setrefer(refer);
         document.querySelector("#hid").value  = (alltasks[1]);
         const direct =Number(alltasks[2]);
         setDirect(direct);
         const directs =  Number(alltasks[22]);
         setDirects(directs);
         const level = Number(alltasks[3]);
         setLevel(level);
         const auto = Number(alltasks[4]);
         setAuto(auto);
         const auto1 = Number(alltasks[11]);
         setAuto1(auto1);
         const auto2 = Number(alltasks[12]);
         setAuto2(auto2);
         
         const auto3 = Number(alltasks[13]);
         setAuto3(auto3);
         
         const auto4 = Number(alltasks[14]);
         setAuto4(auto4);
         const auto5 = Number(alltasks[15]);
         setAuto5(auto5);
         const auto6 = Number(alltasks[16]);
         setAuto6(auto6);
         const auto7 = Number(alltasks[17]);
         setAuto7(auto7);
         

         
         
         
         
         
         const bonus = Number(alltasks[5]);
         setBonus(bonus);
         const bonus1 = Number(alltasks[21]);
         setBonus1(bonus1);
         const total = Number(alltasks[10]);
         setTotal(total);

         const tt = directs + level + bonus + auto + total + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7 + bonus1;
         settt(tt);
         
         const pack = Number(alltasks[6]);
         document.querySelector("#pp").value  = Number(alltasks[6]);
         
    
    setpackage(pack);
    const bonanza = Number(alltasks[7]);
         setBonanza(bonanza);
         const bonanza1 = Number(alltasks[20]);
         setBonanza1(bonanza1);
    const dd = new Date(alltasks[8].timestamp * 1000).toLocaleString();
    setDD(dd);
         setB(b);
         const referlink = 'https://globalpool.io/?ref='+ account;
         setreflink(referlink);
         document.querySelector("#link").value =referlink;
    if(pack==30)
    { 
      document.querySelector("#test").value = "Upgrade USDT 50";
    
    }
    else if(pack==80)
    { 
      document.querySelector("#test").value = "Upgrade USDT 100";
    
    }
    else if(pack ==180)
    {
      document.querySelector("#test").value = "Upgrade USDT 250";
    }
    else if(pack ==430)
    {
      
      document.querySelector("#test").value = "Upgrade USDT 500";
    }
    else if(pack ==930)
    {
      document.querySelector("#test").value = "Upgrade USDT 1000";
    }
    
    else if(pack ==1930)
    {
      document.querySelector("#test").value = "Upgrade USDT 2000";
    }
    else if(pack ==3930)
    {
      document.querySelector("#test").value = "Upgrade USDT 5000";
    }
    else if(pack ==8930)
    {

      document.querySelector("#test").value = "All Packages Purchased";
    }
    
    console.log(pack);
    
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
     
    
    
    connectWallet();
    
  }, []);

 
  // console.log(state);
        
       
  return (
   
<div >
<input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>

    <div class="page-container">
 
      
            
         <div class="header-main">
           <div class="logo-w3-agile">
               <img src ="logo.png" width="200px" height="150px" /><br></br>
                    
               
               </div></div> 
              
               <table width="80%">
<tr>
  <td style={{width: "100px",color: "black",
}} ><h2> 100% Fully Decenterlized AutoPool System.</h2></td></tr>
         </table>      
           
             
         <div class="clearfix"> </div>	 
<table width="100%">
<tr><td style={{width :"1%"}}><br></br></td> 
  <td style={{
backgroundColor: "#f04343"
,width :"92%",height :"100%", verticalAlign:"center", textAlign: "left",
color: "white",fontSize: "12px",
}} >Welcome To GlobalPool : Connected Account - {account} </td><td style={{width :"1%"}}><br></br></td> </tr>
  <tr><td></td></tr>
  <tr> <td style={{width :"1%"}}><br></br></td> <td style={{
backgroundColor: "#66b066",width :"100%",height :"100%", verticalAlign:"center", textAlign: "left",
color: "white",fontSize: "12px",
}}>Refer Link - {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
  navigator.clipboard.writeText(referlink);}} /></button></td></tr><tr><td></td></tr>
  <tr><td style={{width :"1%"}}><br></br></td>  <td style={{
backgroundColor: "#bf5ad1", width :"92%",height :"100%", verticalAlign:"center", textAlign: "left",
color: "white",fontSize: "12px",
}}><strong> My Refer :  {refer} </strong></td><td style={{width :"1%"}}><br></br></td> 
</tr>

</table>
      <br></br>  
<table width="100%">
<tr>    
<td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#f04343",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>

<h4>My Total Packages</h4>
<h5>  USDT {pack}  </h5> </td><td style={{width :"1%"}}><br></br></td>  </tr>
<tr><td></td></tr>
<tr>   
<td style={{width :"1%"}}><br></br></td>   
<td style={{
backgroundColor: "#f04343",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}} > 	<h4>My Direct Income</h4>
<h5>USDT {directs}</h5>
</td></tr>
<tr><td></td></tr>
<tr>  
<td style={{width :"1%"}}><br></br></td>    
<td style={{
backgroundColor: "#f04343",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}} > 	<h4>My Direct Team</h4>
<h5>{direct}</h5>
</td><td style={{width :"1%"}}><br></br></td>  </tr>
<tr><td></td></tr>
<tr> 
<td style={{width :"1%"}}><br></br></td>     
<td style={{
backgroundColor: "#f04343",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",color: "white",
}} > 	<h4>Level Income</h4>
<h5>USDT {level}</h5>
</td></tr>
<tr><td></td></tr>
<tr> 
<td style={{width :"1%"}}><br></br></td>  
  <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",color: "white",
}}><h4>My GlobalPool - I Income</h4>
<h5> USDT {auto}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",color: "white",
}}><h4>My GlobalPool - II Income</h4>
<h5> USDT {auto1}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr> 
    
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#66b066",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My GlobalPool - III Income</h4>
<h5> USDT {auto2}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My GlobalPool - IV Income</h4>
<h5> USDT {auto3}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My GlobalPool - V Income</h4>
<h5> USDT {auto4}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr> 
    
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My GlobalPool - VI Income</h4>
<h5> USDT {auto5}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My GlobalPool - VII Income</h4>
<h5> USDT {auto6}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My GlobalPool - VIII Income</h4>
<h5> USDT {auto7}  </h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My Booster - I Income</h4>
<h5>USDT {bonus}</h5>
  </td></tr><tr><td></td></tr>
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My Booster - II Income</h4>
<h5>USDT {bonus1}</h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#66b066",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}><h4>My Total Income</h4>
<h5> USDT {tt}</h5>
  </td><td style={{width :"1%"}}><br></br></td>  </tr><tr><td></td></tr>
 
 
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#bf5ad1",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <h4>Purchase Booster - I</h4>
                <h5>USDT - 50</h5>
								 <Buy2 state={state} />
</td><td style={{width :"1%"}}><br></br></td>  
</tr>
<tr><td></td></tr>
 
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#bf5ad1",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
   <h4>Purchase Booster - II</h4>
                <h5>USDT - 100</h5>
								 <Buy5 state={state} />
</td><td style={{width :"1%"}}><br></br></td>  
</tr>

<tr><td></td></tr>
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#d67e33",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",

}}>
  <h4>Booster - I Purchased</h4>
                <h5>USDT {bonanza}</h5>
</td><td style={{width :"1%"}}><br></br></td>  
</tr>

<tr><td></td></tr>
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#d67e33",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <h4>Booster - II Purchased</h4>
                <h5>USDT {bonanza1}</h5>
</td><td style={{width :"1%"}}><br></br></td>  
</tr>
<tr><td></td></tr>
  <tr>
  <td style={{width :"1%"}}><br></br></td>  
     <td style={{
backgroundColor: "#d67e33",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <h4>Downline Upgrade Income</h4>
                <h5>USDT {total}</h5>
</td><td style={{width :"1%"}}><br></br></td>  
</tr>

<tr><td></td></tr>
  <tr> 
  <td style={{width :"1%"}}><br></br></td>  
    <td style={{
backgroundColor: "#d67e33",
width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  	<h5> <input type ="text" style={{backgroundColor: "#d67e33" ,textAlign:"center", border :"0" } }id ="test"></input></h5>
                            <Buy state={state} />
</td><td style={{width :"1%"}}><br></br></td>  
</tr>
<tr><td></td></tr>
  <tr> <td>
  	 
								
</td>
</tr>
<tr><td>
<img src="global.gif" alt ="" width="100%"></img>
  </td></tr>
</table>
       
   

   

                          
                          
    
    <div class="clearfix"></div>
  

<div class="copyrights">
	 <p>© 2023 GlobalPool. All Rights Reserved  </p>
</div>	

</div>
</div>


       

           
                   
	




     

  );
}

export default App;
